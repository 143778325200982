<template>
  <div class="flex w-full items-center relative ">
   
    <div
      class="swiper-container "
      :class="[
        container_classes,
        `gallery-top-${_uid}`,
        !remove_radius_bottom ? 'p-bottom-pagination-mobile' : '',
      ]"
      :style="with_swiper_pagination ? 'padding-bottom: 2.25rem!important;': ''"
    >
      <div class="swiper-wrapper">
        <slot v-if="is_slot"></slot>

        <div
          v-else
          v-for="(image, index) in images"
          :key="index"
          :style="`background-image: url( ${
            !with_base_file ? image : $base_file + '' + image
          } )`"
          class="swiper-slide rounded-box"
          :class="{ 'remove-radius-bottom': remove_radius_bottom }"
        ></div>
      </div>
      <div class="flex w-full justify-center h-4" v-if="with_swiper_pagination">
        <div
          class="swiper-pagination"
          :class="`swiper-pagination-${_uid}`"
          
        ></div>
      </div>

    </div>
   
      <div
      v-if="isNavigated"
        class=" swiper-next"
        :id="`button-prev-${_uid}`"
        slot="button-prev"
      >
      <ChevronLeftIcon class="w-6 h-6 text-white opacity-60"></ChevronLeftIcon>
      </div>

      <div
      v-if="isNavigated"
        class="  swiper-prev"
        :id="`button-next-${_uid}`"
        slot="button-prev"
      >
     <ChevronRightIcon class="w-6 h-6  text-white opacity-60"></ChevronRightIcon>
      </div>
      

    <div class="flex w-full pt-4 items-center sm:hidden" v-if="with_thumps">
      <div
        class="outile-none border-none overflow-hidden rounded-full w-12 h-12 flex items-center justify-center mr-4 outline-none sm-ws:mr-2 sm-ws:h-8 sm-ws:w-8"
        :id="`button-prev-${_uid}`"
      >
        <svg
          class="w-11 h-11 fill-current text-promy-gray-500 hover:text-promy-green-300 outile-none border-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 44 44"
        >
          <g id="arrow_group_left" transform="translate(-448 -1134)">
            <g id="arrow" transform="translate(-1042.764 340.578)">
              <path
                id="Tracé_14"
                data-name="Tracé 14"
                d="M1505.927,816.325h0a.59.59,0,0,1,0-.814h0l.558-.576,4.206-4.344a.545.545,0,0,1,.789,0l.557.575a.588.588,0,0,1,0,.814l-1.909,1.973a.578.578,0,0,0,.394.982h9.685a.567.567,0,0,1,.557.576v.814a.567.567,0,0,1-.557.575h-9.685a.578.578,0,0,0-.394.983l1.916,1.979a.59.59,0,0,1,0,.815l-.557.575a.545.545,0,0,1-.789,0l-4.213-4.353Z"
                fill="currentColor"
              />
            </g>
            <g
              id="Ellipse_1689"
              data-name="Ellipse 1689"
              transform="translate(448 1134)"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            >
              <circle cx="22" cy="22" r="22" stroke="none" />
              <circle cx="22" cy="22" r="21" fill="none" />
            </g>
          </g>
        </svg>
      </div>
      <div
        class="swiper-container h-24 sm:h-18 sm-ws:h-18 gallery-thumbs"
        ref="swiperThumbs"
        :class="`gallery-thumbs-${_uid}`"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(image, index) in images"
            :key="index"
            :style="`background-image: url( ${
              !with_base_file ? image : $base_file + '' + image
            } )`"
            class="swiper-slide rounded-box"
          ></div>
        </div>
      </div>
      <div
        class="outile-none border-none overflow-hidden rounded-full w-12 h-12 flex items-center justify-center ml-4 outline-none sm-ws:ml-2 sm-ws:h-8 sm-ws:w-8"
        :id="`button-next-${_uid}`"
        slot="button-prev"
      >
        <svg
          class="w-12 h-12 fill-current text-promy-gray-500 hover:text-promy-green-300 "
          viewBox="0 0 44 44"
        >
          <g id="arrow_group" transform="translate(-1838 -1134)">
            <g id="arrow" transform="matrix(-1, 0, 0, -1, 3372.764, 340.578)">
              <path
                id="Tracé_13"
                data-name="Tracé 13"
                d="M1505.927-816.325h0a.59.59,0,0,0,0,.814h0l.558.576,4.206,4.344a.545.545,0,0,0,.789,0l.557-.575a.588.588,0,0,0,0-.814l-1.909-1.973a.578.578,0,0,1,.394-.982h9.685a.567.567,0,0,0,.557-.576v-.814a.567.567,0,0,0-.557-.575h-9.685a.578.578,0,0,1-.394-.983l1.916-1.979a.59.59,0,0,0,0-.815l-.557-.575a.545.545,0,0,0-.789,0l-4.213,4.353Z"
                fill="currentColor"
              />
            </g>
            <g
              id="Ellipse_1689"
              data-name="Ellipse 1689"
              transform="translate(1838 1134)"
              fill="none"
            >
              <path
                d="M22,0A22,22,0,1,1,0,22,22,22,0,0,1,22,0Z"
                stroke="none"
              />
              <path
                d="M 22 2 C 19.29912948608398 2 16.68012046813965 2.528518676757813 14.2157096862793 3.570880889892578 C 11.83435821533203 4.578098297119141 9.695270538330078 6.020450592041016 7.857860565185547 7.857860565185547 C 6.020450592041016 9.695270538330078 4.578098297119141 11.83435821533203 3.570880889892578 14.2157096862793 C 2.528518676757813 16.68012046813965 2 19.29912948608398 2 22 C 2 24.70085906982422 2.528518676757813 27.31987953186035 3.570880889892578 29.7842903137207 C 4.578098297119141 32.16563034057617 6.020450592041016 34.30471801757813 7.857860565185547 36.14212799072266 C 9.695270538330078 37.97954940795898 11.83435821533203 39.42189788818359 14.2157096862793 40.42911911010742 C 16.68012046813965 41.47148132324219 19.29912948608398 42 22 42 C 24.70085906982422 42 27.31986999511719 41.47148132324219 29.7842903137207 40.42911911010742 C 32.16563034057617 39.42189788818359 34.30471801757813 37.97954940795898 36.14212799072266 36.14212799072266 C 37.97954940795898 34.30471801757813 39.42189788818359 32.16563034057617 40.42911911010742 29.7842903137207 C 41.47148132324219 27.31986999511719 42 24.70085906982422 42 22 C 42 19.29912948608398 41.47148132324219 16.68012046813965 40.42911911010742 14.2157096862793 C 39.42189788818359 11.83435821533203 37.97954940795898 9.695270538330078 36.14212799072266 7.857860565185547 C 34.30471801757813 6.020450592041016 32.16563034057617 4.578098297119141 29.7842903137207 3.570880889892578 C 27.31987953186035 2.528518676757813 24.70085906982422 2 22 2 M 22 0 C 34.15026092529297 0 44 9.8497314453125 44 22 C 44 34.15026092529297 34.15026092529297 44 22 44 C 9.8497314453125 44 0 34.15026092529297 0 22 C 0 9.8497314453125 9.8497314453125 0 22 0 Z"
                stroke="none"
                fill="currentColor"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import { ChevronLeftIcon,ChevronRightIcon } from '@vue-hero-icons/outline'

// import Swiper JS
import Swiper from "swiper/bundle";

// import Swiper styles
import "swiper/swiper-bundle.css";
export default {
  components:{
    ChevronLeftIcon,
    ChevronRightIcon
  },
  data() {
    return {
      galleryTop: null,
    };
  },
  props: {
    images: {
      type: Array,
    },
    with_base_file: {
      type: Boolean,
      default: false,
    },

    with_thumps: {
      type: Boolean,
      default: false,
    },
    container_classes: {
      type: String,
      default: "h-96 sm:h-86 sm-ws:h-96  ",
    },
    is_swiper_pagination_web: {
      type: Boolean,
      default: false,
    },
    is_slot: {
      type: Boolean,
      default: false,
    },
    isNavigated: {
      type: Boolean,
      default: true,
    },
    remove_radius_bottom: {
      type: Boolean,
      default: false,
    },
    with_swiper_pagination:{
      type: Boolean,
      default: false,
    }
  },
  methods: {
    swipeTo(index) {
      this.galleryTop.slideTo(index);
    },
  },
  mounted() {
    this.$nextTick(() => {
      let galleryThumbs = null;
      if (this.with_thumps) {
        galleryThumbs = new Swiper(`.gallery-thumbs-${this._uid}`, {
          spaceBetween: 10,
          slidesPerView: 3,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        });
      }

      this.galleryTop = new Swiper(`.gallery-top-${this._uid}`, {
        observer: true,
        observeParents: true,
        slidesPerView: "auto",
        pagination: {
          el: `.swiper-pagination-${this._uid}`,
          clickable: true,
        },
        spaceBetween: 10,

        navigation: {
          nextEl: `#button-next-${this._uid}`,
          prevEl: `#button-prev-${this._uid}`,
        },
        thumbs: {
          swiper: galleryThumbs,
        },
      });
      this.galleryTop.on("slideChangeTransitionEnd", () => {
        this.$emit("getIndexSlider", this.galleryTop.realIndex);
      });
      this.galleryTop.on("transitionEnd", () => {
        if (this.galleryTop.isEnd) {
          this.$emit("isEnd");
        }
      });
    });
  },
};
</script>
<style lang="scss" >
.swiper-pagination-bullet {
    @apply w-3 h-3;
}
.swiper-pagination-bullet-active {
    background-color: #3700AC;
    border-color: #9873E8;
    @apply  border-4  w-5 h-5;
}
.swiper-pagination-bullets {
    @apply flex items-center space-x-5;
}
.swiper-pagination {
    left: auto !important;
    width: auto !important;
}

</style>
<style lang="scss" scoped>

.swiper-next,
.swiper-prev {
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 28px);
}
.swiper-prev {
    right: 10px;
    left: auto;
}
.swiper-next {
    left: 10px;
    right: auto;
}
.remove-radius-bottom {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.swiper-container {
  width: 100%;

  margin-left: auto;
  margin-right: auto;
  
}
.p-bottom-pagination-mobile {
  @screen sm {
    padding-bottom: 2rem !important;
  }
}

.pb-container {
  padding-bottom: 2rem !important;
}
.xs-pb-container {
  padding-bottom: 1rem !important;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.gallery-thumbs {
  box-sizing: border-box;
}

.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-button-white {
  display: none;
}

.swiper-pagination {
  bottom: 0.25rem !important;
}
</style>