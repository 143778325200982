import Vue from "vue";
import VueRouter from "vue-router";

function page(path) {
  return () => import( /* webpackChunkName: '' */ `../pages/${path}`);
}

Vue.use(VueRouter);

const routes = [{
    path: "/programToReserve",
    name: "reservationLots",
    component: page("reservationLots"),
  },
  {
    path: "/programme-non-disponible",
    name: "programmeNotFound",
    component: page("pagesErreur/programmeNotFound"),
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: page("pagesErreur/forbidden"),
  },
  {
    path: '*',
    component: page("pagesErreur/pageNotFound"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;