<template>
  <div class="modal-mask flex flex-col sm:px-2" v-if="showModal">
    <div class="modal-wrapper" :class="classes">
      <div class="flex justify-between mb-4" v-if="is_header_outside_box">
        <span
          class="text-xl font-extrabold sm:text-base text-promy-purple-300 font-main"
        >
          {{ title }}
        </span>
        <img src="/images/close.svg" @click="close" class="cursor-pointer" />
      </div>
      <div
        class="flex-col flex modal-container relative"
        :class="classes_container"
      >
        <img
          v-if="!is_header_outside_box"
          src="/images/close.svg"
          @click="close"
          class="cursor-pointer absolute right-0 mr-6 top-0 mt-6"
        />
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    classes: {
      type: String,
      default: "w-fit",
    },
    is_header_outside_box: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    classes_container: {
      type: String,
      default: "py-8 px-10",
    },
  },
  methods: {
    close() {
      this.$emit("closeModel");
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-mask {
  @apply fixed top-0 left-0 w-full h-full  flex justify-center items-center mt-0;
  z-index: 9998;
  background-color: rgba(255, 255, 255, 0.8);
  transition: opacity 0.3s ease;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .modal-container {
      @apply rounded-box  bg-white m-auto shadow-box;
      transition: all 0.1s ease;
    }
  }
  .w-fit {
    width: fit-content;
  }
}
</style>

 