import axios from "axios";
import router from "@/router";
import Vue from "vue";

const transport = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});
Vue.prototype.$http = transport;

// Response interceptor
transport.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      status
    } = error.response;
    if (status === 404) {
      router.push("/programme-non-disponible");
    } else if (status === 403) {
      router.push("/forbidden");
    }
    return Promise.reject(error);
  }
);
export default transport;