<template>
  <ul class="accordion">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  props: {
    shouldOpenLastItem: Boolean,
  },
  data() {
    return {
      Accordion: {
        count: 1,
        active: null,
      },
    };
  },
  provide() {
    return {
      Accordion: this.Accordion,
      shouldOpenLastItem: this.shouldOpenLastItem,
    };
  },
};
</script>

<style scoped>
.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
}
.item:last-child {
  border-bottom: none;
}
</style>
